import React, { useState } from "react";
import MobileBanner from "../images/mobile_banner.png";
import DesktopBanner from "../images/website_banner.png";
import PlayStoreButton from "../images/Google Play.svg";
import AppStoreButton from "../images/App Store.svg";

export default function MobileAppAnnouncement() {
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("showBanner") !== "false"
  );

  const closeBanner = () => {
    localStorage.setItem("showBanner", "false");
    setShowBanner(false);
  };

  const redirectToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.onlyoptions.only_options",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/1lyoption-analyse-and-trade/id6739134436",
      "_blank"
    );
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="announcement-banner-container">
      <div className="announcement-banner">
        <img
          src={window.innerWidth <= 768 ? MobileBanner : DesktopBanner}
          alt="New Product Announcement"
          className="banner-image"
        />
        <button className="banner-close-btn" onClick={closeBanner}>
          &times;
        </button>
        <div className="d-flex flex-column flex-md-row justify-content-center gap-1 gap-md-3 position-absolute play-app-store-btn-container align-items-center">
          <img
            src={PlayStoreButton}
            alt="button to play store"
            className="cursor-pointer"
            onClick={redirectToPlayStore}
          />
          <img
            src={AppStoreButton}
            alt="button to app store"
            className="cursor-pointer"
            onClick={redirectToAppStore}
          />
        </div>
      </div>
    </div>
  );
}
